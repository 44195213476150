import { I18nSelectPipe, registerLocaleData } from '@angular/common';
import * as localforage from 'localforage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ApplicationRef, APP_INITIALIZER, ErrorHandler, Injector, NgModule, NgZone } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationStart, NoPreloading, Router, RouterModule } from '@angular/router';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';
import { filter, pairwise } from 'rxjs/operators';
import { I18nMessagesSelect } from '../assets/i18n/messages.selects';
import { COMMON_PROVIDERS } from '../environments/common-providers';
import { AuthService } from './+auth/services/auth.service';
import { LadderInfluenceService } from './+home/services/ladder-influence.service';
import { SearchProvider } from './+search/providers/search.provider';
// App is our top level component
import { AppComponent } from './app.component';
/*
 * Platform and Environment providers/directives/pipes
 */
import { ROUTES } from './app.routes';
import { ErrorService } from './crosscutting/error-handling.service';
import { ErrorMessagesService } from './crosscutting/exceptions/error-messages.service';
import { ExceptionErrorMessagesProvider } from './crosscutting/exceptions/exception-error-messages.provider';
import { ExceptionTranslationProvider } from './crosscutting/exceptions/exception-translation.provider';
import { AuthInterceptor } from './crosscutting/Interceptors/auth-interceptor';
import { RetryInterceptor } from './crosscutting/Interceptors/retry-interceptor';
import { StoreHelper } from './crosscutting/store-helper';
import { UncaughtExceptionHandler } from './crosscutting/uncaught-exception-handler';
import { Url } from './crosscutting/urls';
import { AuthProvider } from './providers/auth.provider';
import { BodyProvider } from './providers/body.provider';
import { CountryProvider } from './providers/country.provider';
import { CurrentUserProvider } from './providers/current-user.provider';
import { LanguageProvider } from './providers/language.provider';
import { LoginProvider } from './providers/login.provider';
import { SocialMediaProvider } from './providers/social-media.provider';
import { TestValuesProvider } from './providers/test-values.provider';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { AuthLayoutComponent } from './shared/layout/app-layouts/auth-layout.component';
import { EmptyLayoutComponent } from './shared/layout/app-layouts/empty-layout.component';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { NavMobileComponent } from './shared/layout/header/nav-mobile.component';
import { UserHeaderComponent } from './shared/layout/header/user-header.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'primeng/tooltip';

registerLocaleData(localePt);

export function jwtOptionsFactory(tokenService) {
  return {
    tokenGetter: () => {
      try {
        return StoreHelper.token.access_token;
      } catch (error) {
        return '';
      }
    }
  };
}

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'adminCache',
  version: 1.0,
  storeName: 'generic'
});

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    EmptyLayoutComponent,
    UserHeaderComponent,
    NavMobileComponent,
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedComponentsModule,
    TooltipModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: []
      }
    }),
    RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: NoPreloading }),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    COMMON_PROVIDERS,
    I18nSelectPipe,
    Url,
    I18nMessagesSelect,
    AuthService,
    MessageService,
    ErrorMessagesService,
    ExceptionErrorMessagesProvider,
    ExceptionTranslationProvider,
    BodyProvider,
    AuthProvider,
    LanguageProvider,
    CurrentUserProvider,
    SocialMediaProvider,
    CountryProvider,
    TestValuesProvider,
    LoginProvider,
    LadderInfluenceService,
    ErrorService,
    SearchProvider,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true, deps: [] },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true, deps: [Router, Url, NgZone, LoginProvider, ErrorService, ErrorMessagesService] },
    { provide: ErrorHandler, useClass: UncaughtExceptionHandler, deps: [Injector, NgZone, ErrorService] },
  ]
})
export class AppModule {
  public static previousUrl: string;

  constructor(public router: Router, public appRef: ApplicationRef) {
    this.router.events.pipe(filter(e => e instanceof NavigationStart), pairwise()).subscribe((e) => {
      AppModule.previousUrl = (e.shift() as NavigationStart).url;
  });
  }
}
