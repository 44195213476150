import { Injectable } from '@angular/core';
import { InfluencerSocialNetwork } from '../shared/models/influencer-social-network.model';
import { isNullOrUndefined } from 'util';
import { Influencer } from '../shared/models/influencer.model';
import { InfluencerUpdate } from '../shared/models/influencer-update.model';
import { Observable } from 'rxjs';
import { EnumSocialNetwork } from '../shared/models/enum-social-network.model';
import { LookALikeInfluencer } from 'app/shared/models/look-alike-influencer.model';

@Injectable()
export class SocialMediaProvider {
    constructor() {
    }
    public static DEFAULT_URL = '/assets/img/user-default.png';

    public minifyFollowers(followers: number): string {
        if (followers > 999999) {
            return `${(followers / 1000000).toFixed(0)} M`;
        }
        if (followers > 999) {
            return `${(followers / 1000).toFixed(0)} K`;
        }
        return `${followers}`;
    }

    public getTotalFollowers(socialMedias: InfluencerSocialNetwork[]): string {
        let followers = 0;
        socialMedias.forEach((element: InfluencerSocialNetwork) => {
            followers = followers + element.totalFollowers;
        });
        return this.minifyFollowers(followers);
    }

    public getResonanceAverage(influencer: Influencer | InfluencerUpdate): string {
        return isNullOrUndefined(influencer.resonance) || influencer.resonance === 0 ? ' - ' : influencer.resonance.toFixed(2) + ' %';
    }

    public getProfilePictureUrlOrGetBestSocialNetworkPictureUrl(influencer: Influencer | LookALikeInfluencer, size: number = 24, force: boolean = false): string {
        if ((influencer as Influencer).pictureUrl !== null && (influencer as Influencer).pictureUrl !== undefined) {
            return (influencer as Influencer).pictureUrl;
        }
        if (influencer.influencerSocialNetworks.length > 0) {
            influencer.influencerSocialNetworks = this.sortSocialNetwork(influencer.influencerSocialNetworks);
            let modelNetwork = influencer.influencerSocialNetworks[0];
            if (!force) {
                for (let index = 0; index < influencer.influencerSocialNetworks.length; index++) {
                    modelNetwork = influencer.influencerSocialNetworks[index];
                    if (modelNetwork.socialNetworkInfluencerPictureUrl !== null && modelNetwork.socialNetworkInfluencerPictureUrl !== undefined) {
                        switch (modelNetwork.socialNetworkName) {
                            case 'Facebook':
                                return `https://graph.facebook.com/${modelNetwork.socialNetworkExternalId}/picture?height=${size}`;
                            default:
                                return modelNetwork.socialNetworkInfluencerPictureUrl;
                        }
                    }
                }
            }

            switch (modelNetwork.socialNetworkName) {
                case 'Facebook':
                    return `https://graph.facebook.com/${modelNetwork.socialNetworkExternalId}/picture?height=${size}`;
                default:
                    break;
            }

            return SocialMediaProvider.DEFAULT_URL;
        }
    }

    public getProfileBestSocialNetworkAsync(influencer: Influencer, size: number, callback, component) {
        const me = this;
        Observable.create((obs) => {
            const url = me.getProfilePictureUrlOrGetBestSocialNetworkPictureUrl(influencer, size);
            obs.next(url);
            obs.complete();
        }).subscribe((res) => {
            callback(res, component);
        });
    }

    public getLinkSocialNetwork(influencerSocialNetwork: InfluencerSocialNetwork) {
        return this.getLinkSocialNetworkBySocialNetworkExternalId(influencerSocialNetwork.socialNetworkExternalId, influencerSocialNetwork.socialNetworkName);
    }

    public getLinkSocialNetworkBySocialNetworkExternalId(socialNetworkExternalId: string, socialNetworkName: string) {
        switch (socialNetworkName) {
            case 'Facebook':
                return 'https://facebook.com/' + socialNetworkExternalId; 
            case 'Youtube':
                return 'https://youtube.com/channel/' + socialNetworkExternalId;
            case 'Instagram':
                return 'https://www.instagram.com/' + socialNetworkExternalId;
            case 'Linkedin':
                return 'https://www.linkedin.com/in/' + socialNetworkExternalId;
            default:
                break;
        }
    }

    public sortSocialNetwork(socialMedias: InfluencerSocialNetwork[]): InfluencerSocialNetwork[] {
        return socialMedias.sort((a: InfluencerSocialNetwork, b: InfluencerSocialNetwork) => {
            return b.totalFollowers - a.totalFollowers;
        });
    }

    public socialNetworkPictureUrl(socialNetworkName: string): string {
        switch (socialNetworkName) {
            case 'Facebook':
                return `social-icon facebook fa fa-facebook`; 
            case 'Youtube':
                return `social-icon youtube fa fa-youtube-play`;
            case 'TikTok':
                return `social-icon tiktok fa-brands fa-tiktok`;
            case 'Google':
                return `social-icon googleplus fa fa-google-plus`;
            case 'Instagram':
                return `social-icon instagram fa fa-instagram`;
            case 'Linkedin':
                return `social-icon linkedin fa fa-linkedin`;
            default:
                break;
        }
    }

    public getSocialNetworkNameFromEnum(socialNetwork: EnumSocialNetwork): string {
        switch (socialNetwork) {
            case EnumSocialNetwork.Facebook: return 'Facebook';
            case EnumSocialNetwork.Instagram: return 'Instagram';
            case EnumSocialNetwork.Youtube: return 'Youtube';
            default:
                break;
        }
    }

    public getInstagramLocationUrl(locationId: string): string {
        return `https://www.instagram.com/explore/locations/${locationId}`;
    }
}
