import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './shared/layout/app-layouts/auth-layout.component';
import { EmptyLayoutComponent } from './shared/layout/app-layouts/empty-layout.component';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';

export const ROUTES: Routes = [
  {
    path: '', component: MainLayoutComponent, children: [
      { path: '', loadChildren: () => import('./+influencer/+grid').then(m => m.InfluencerGridModule) },
      { path: 'home', loadChildren: () => import('./+home').then(m => m.HomeModule) },
      { path: 'search', loadChildren: () => import('./+search').then(m => m.SearchModule) },
      { path: 'dataset', loadChildren: () => import('./+dataset').then(m => m.DatasetModule) },
      { path: 'archetype', loadChildren: () => import('./+archetype').then(m => m.ArchetypeModule) },
      { path: 'influencergrid', loadChildren: () => import('./+influencer/+grid').then(m => m.InfluencerGridModule) },
      { path: 'influencer-profile/edit/:id', loadChildren: () => import('./+influencer/+profile').then(m => m.InfluencerProfileModule) },
      { path: 'list-customer', loadChildren: () => import('./+account/+manager/list-customer').then(m => m.ListCustomerModule) },
      { path: 'edit-customer/:id', loadChildren: () => import('./+account/+manager/edit-customer').then(m => m.EditCustomerModule) },
      { path: 'radar', loadChildren: () => import('./+radar').then(m => m.RadarModule) },
      { path: 'plans', loadChildren: () => import('./+plans').then(m => m.PlansModule) },
      { path: 'automation', loadChildren: () => import('./+automation').then(m => m.AutomationModule) },
      { path: 'cpm', loadChildren: () => import('./+cpm').then(m => m.CpmModule) },
      { path: 'keywords', loadChildren: () => import('./+keywords').then(m => m.KeywordsModule) },
      { path: 'users', loadChildren: () => import('./+user/+grid').then(m => m.UserGridModule) },
      { path: 'user-details/:id', loadChildren: () => import('./+user/+details').then(m => m.UserDetailsModule) },
      { path: 'userInfluencers', loadChildren: () => import('./+user/+influencers').then(m => m.UserInfluencersModule) },
      { path: 'loginInformation', loadChildren: () => import('./+crawler-login-information').then(m => m.CrawlerLoginInformationModule) },
      { path: 'quotations', loadChildren: () => import('./+quotation/+grid').then(m => m.QuotationGridModule) },
      { path: 'quotation-details/:id', loadChildren: () => import('./+quotation/+details').then(m => m.QuotationDetailsModule) },
      { path: 'stars', loadChildren: () => import('./+stars/+grid').then(m => m.StarsGridModule) },
      { path: 'stars-influencer-details/:id', loadChildren: () => import('./+stars/+influencer-details').then(m => m.StarsInfluencerDetailsModule) },
      { path: 'stars-campaign-details/:id', loadChildren: () => import('./+stars/+campaign-details').then(m => m.StarsCampaignDetailsModule) }
    ]
  },
  {
    path: 'error', component: MainLayoutComponent, children: [
      { path: '', loadChildren: () => import('./+exception-handler').then(m => m.ShowExceptionModule) }
    ]
  },
  { path: 'empty', component: EmptyLayoutComponent, children: [] },
  {
    path: 'auth', component: AuthLayoutComponent, children: [
      { path: '', loadChildren: () => import('./+auth/+login').then(m => m.LoginModule) },
      { path: 'login', loadChildren: () => import('./+auth/+login').then(m => m.LoginModule) },
      { path: 'logout', loadChildren: () => import('./+auth/+logout').then(m => m.LogoutModule) },
      { path: 'sigin-external-login', redirectTo: 'login/sigin-external' },
    ]
  }
];
